import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import { useForm, ValidationError } from '@formspree/react';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});
  const [state, handleSubmit] = useForm("mpznolek");
  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }
  console.log("handlesubmit", handleSubmit);
  const onBtnClick = () => {
    if (formDetails.email != '' &&
      formDetails.firstName != '' &&
      formDetails.lastName != '' &&
      formDetails.message != '') {
      setStatus({ success: true, message: 'Message sent successfully. I will get back to you, I promise' });
      setTimeout(() => {
        setFormDetails(formInitialDetails);
        setStatus({ success: false, message: null });
      }, 5000)
    } else {
      setStatus({ success: false, message: 'Fill all the details! You can leave phone number if you want to' });
      setTimeout(() => {
        setStatus({ success: false, message: null });
      }, 5000)
    }

  }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setButtonText("Sending...");
  //   let response = await fetch("http://localhost:5000/contact", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json;charset=utf-8",
  //     },
  //     body: JSON.stringify(formDetails),
  //   });
  //   setButtonText("Send");
  //   let result = await response.json();
  //   setFormDetails(formInitialDetails);
  //   if (result.code == 200) {
  //     setStatus({ succes: true, message: 'Message sent successfully' });
  //   } else {
  //     setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
  //   }
  // };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input name="firstName" type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                        <ValidationError
                          prefix="firstName"
                          field="firstName"
                          errors={state.errors}
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input name="lastName" type="text" value={formDetails.lasttName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                        <ValidationError
                          prefix="lastName"
                          field="lastName"
                          errors={state.errors}
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input name="emailId" type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                        <ValidationError
                          prefix="emailId"
                          field="emailId"
                          errors={state.errors}
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input name="phoneNum" type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
                        <ValidationError
                          prefix="phoneNum"
                          field="phoneNum"
                          errors={state.errors}
                        />
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea name="message" rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                        <ValidationError
                          prefix="message"
                          field="message"
                          errors={state.errors}
                        />
                        <button onClick={onBtnClick} type="submit"><span>{buttonText}</span></button>
                      </Col>

                    </Row>
                  </form>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <div className="messageContainer">
        {
          status.message &&
          <h3 className={status.success === false ? "danger" : "success"}>{status.message}</h3>

        }
      </div>
      {/* <h3 className={status.success === false ? "danger" : "success"}>{status.message}</h3> */}
    </section>
  )
}
