import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logoName.jpeg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/gitLog.png";
import { FiMail, FiPhone } from 'react-icons/fi'
export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6} className="text-center">
            <div>
              <h1>Call On</h1>
              <a href="tel: +917617072452"><p className="p__opensans"><FiPhone style={{ color: '#FFF', marginRight: '10px' }} />+91 7617072452</p></a>
            </div>
          </Col>
          <Col size={12} sm={6} className="text-center">
            <div>
              <h1>Email at</h1>
              <a href="mailto: dhananjayjaiswal16.10@gmail.com"><p className="p__opensans" style={{ textTransform: 'lowercase' }}><FiMail style={{ color: '#FFF', marginRight: '10px' }} />dhananjayjaiswal16.10@gmail.com</p></a>
            </div>
          </Col>
          <Col size={12} sm={12} className="text-center">
            <div className="social-icon">
              <a target="_blank" href="https://www.linkedin.com/in/dhananjayjaiswal16/"><img src={navIcon1} alt="Icon" /></a>
              <a target="_blank" href="https://github.com/dhananjayjaiswal16/"><img src={navIcon2} alt="Icon" /></a>
            </div>
            <p>Copyright {new Date().getFullYear()}. All Rights Reserved © Dhananjay</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
