import { Col } from "react-bootstrap";
import { FaChrome, FaGithub } from 'react-icons/fa';

export const ProjectCard = ({ title, description, imgUrl, live, code }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <a target="_blank" style={{ color: "#ffc5e8", marginRight: "30px", textDecoration: "none", fontSize: "22px" }} href={live}>Visit Live <FaChrome className="gallery_icons" /></a>
          <a target="_blank" style={{ color: "#ffc5e8", textDecoration: "none", fontSize: "22px" }} href={code}>See Code <FaGithub className="gallery_icons" /></a>
        </div>
      </div>
    </Col>
  )
}
