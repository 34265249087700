import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"
import myImg from "../assets/img/myImg.jpeg";
import styles from "./Skills.module.css";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <div className={styles.contentWrapper}>
                <img src={myImg} alt="Dj-img" srcset="" />
                <p><h1 style={{ color: "#4A2FBD" }}>A bit about me</h1><br></br> I Am A Full Stack Developer. Over that I am a trading enthusiast, Javascript aficionado and a die hard Messi fan. <br></br><br></br> At present you can find me 𝔹ℝ𝔼𝔸𝕂𝕀ℕ𝔾 𝔹𝕌𝕀𝕃𝔻𝕊 at <a style={{ color: "#4A2FBD" }} target="_blank" href="https://www.werize.com/">WeRize</a> as a Software Engineer </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
