import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/gallery01.png";
import projImg2 from "../assets/img/gallery02.png";
import projImg3 from "../assets/img/gallery03.png";
import projImg4 from "../assets/img/gallery04.png";
import projImg5 from "../assets/img/gallery05.png";
import projImg6 from "../assets/img/gallery06.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "E-commerce",
      description: "Design & Development",
      imgUrl: projImg1,
      code: "https://github.com/dhananjayjaiswal16/mern-ecommerce",
      live: "https://ecommerce-dj.netlify.app/",
    },
    {
      title: "E-commerce Admin Dashboard",
      description: "Design & Development",
      imgUrl: projImg2,
      code: "https://github.com/dhananjayjaiswal16/dashboard-ecommerce",
      live: "https://ecommerce-admin-dj.netlify.app/",
    },
    {
      title: "Travel Guide",
      description: "Design & Development",
      imgUrl: projImg4,
      code: "https://github.com/dhananjayjaiswal16/travel-guide",
      live: "https://travel-explorer.netlify.app/",
    },
    {
      title: "Cryptocurrency tracker",
      description: "Design & Development",
      imgUrl: projImg3,
      code: "https://github.com/dhananjayjaiswal16/crypto-tracker",
      live: "https://trackerforcryptocurrency.netlify.app/",
    },
    {
      title: "Pizza order app",
      description: "Design & Development",
      imgUrl: projImg5,
      code: "https://github.com/dhananjayjaiswal16/pizza-order-app",
      live: "https://pizza-app.cyclic.app",
    },
    {
      title: "Image generator(Open Ai)",
      description: "Design & Development",
      imgUrl: projImg6,
      code: "https://github.com/dhananjayjaiswal16/FE-open-ai-generator",
      live: "https://openai-image-generator.netlify.app/",
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2 style={{ marginBottom: "50px" }}>Projects</h2>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            projects.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
